/**
 * Custom loader setting for Next/Image component
 */
export default function CustomImageLoader({ src, width, quality }) {
	// If the src url is a cropped image, just return the image src with quality
	if (src.includes('?w')) {
		return `${src}&quality=${quality || 75}`;
	}

	return `${src}?w=${width}&quality=${quality || 75}`;
}
