import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { useRef } from 'react';

import PredictiveSearchBar from '../PredictiveSearch/PredictiveSearchBar';

/**
 * @summary The Header Search Menu.
 * @param {import('./HeaderTypes').SearchMenuProps} props
 * @returns {JSX.Element}
 */
export default function SearchMenu({
	searchId, shouldFocus, setSearchOpen, toggleSearchMenu,
}) {
	const searchMenuRef = useRef(null);

	// close the search on esc key
	const handleCloseOnESC = (event) => {
		if (event.keyCode === 27) {
			event.preventDefault();
			setSearchOpen(false);
			toggleSearchMenu();
		}
	};
	return (
		<FocusTrap
			active={shouldFocus}
			ref={searchMenuRef}
			focusTrapOptions={{ clickOutsideDeactivates: true }}
		>
			<div className="header-searchmenu" onKeyDown={handleCloseOnESC} role="presentation">
				<PredictiveSearchBar
					searchInputId={searchId}
					searchInputLabelId="combobox-search-input-label"
					submitButtonLabel="GO"
					searchPlaceholder="Search"
					shouldFocus={shouldFocus}
					shouldRedirectOnSubmit
				/>
			</div>
		</FocusTrap>
	);
}

SearchMenu.propTypes = {
	searchId: PropTypes.string.isRequired,
	shouldFocus: PropTypes.bool,
	setSearchOpen: PropTypes.func,
	toggleSearchMenu: PropTypes.func,
};

SearchMenu.defaultProps = {
	shouldFocus: false,
	setSearchOpen: () => {},
	toggleSearchMenu: () => {},
};
